export default {
    // ENOTARIUS_ROOT_URL: `${location.protocol}//${location.hostname}:${location.port}/enotarius`,
    // ENOTARIUS_API_URL: `${location.protocol}//${location.hostname}:${location.port}/enotarius/api`,
    // SPEEDTEST_URL: `http://localhost:8085/speedtest/`,
    SPEEDTEST_URL: `https://media-server.adliya.uz/speedtest/`,
    ENOTARIUS_ROOT_URL: `${location.protocol}//${location.hostname}:${location.port}`,
    ENOTARIUS_API_URL: `${location.protocol}//${location.hostname}:${location.port}/api`,
    LUNA_SOCKET: 'wss://faceid.adliya.uz/ws',
    __APP_HASH__: "{{__APP_HASH__}}"
}
